import styled from '@emotion/styled';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import KAKAO_LOGO from '@/assets/kakao_logo.svg';
import { Button } from '@/components/common/Button';
import { UnderlineTextField } from '@/components/common/Form/Input/UnderlineTextField';
import { Spacing } from '@/components/common/layouts/Spacing';
import { breakpoints } from '@/styles/variants';

type AuthRequestBody = {
  email: string;
  password: string;
};

type LoginResponse = {
  email: string;
  token: string;
};

type ErrorResponse = {
  errorMessage: string;
};

const isErrorResponse = (error: unknown): error is { response: { data: ErrorResponse } } => {
  return typeof error === 'object' && error !== null &&
          'response' in error && 
          typeof (error as { response?: unknown }).response === 'object' &&
          'data' in (error as { response: { data?: unknown } }).response &&
          typeof (error as { response: { data: { errorMessage?: unknown } } }).response.data.errorMessage === 'string';
};

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [queryParams] = useSearchParams();

  useEffect(() => {
    const savedEmail = localStorage.getItem('email');
    if (savedEmail) {
      setEmail(savedEmail);
    }
  }, []);

  const handleLogin = async () => {
    if (!email || !password) {
      alert('이메일과 비밀번호를 입력해주세요.');
      return;
    }

    try {
      const response = await axios.post<LoginResponse>('/api/members/login', {
        email,
        password,
      } as AuthRequestBody);
      console.log('로그인 성공:', response.data);
      localStorage.setItem('token', response.data.token);
      alert('로그인 성공');
      const redirectUrl = queryParams.get('redirect') ?? `${window.location.origin}/`;
      window.location.replace(redirectUrl);
    } catch (err: unknown) {
      if (isErrorResponse(err)) {
        console.error('로그인 실패:', err.response.data); // 서버 오류 확인을 위해 콘솔에 오류 메시지 출력
        alert('로그인 실패: ' + err.response.data.errorMessage);
      } else {
        console.error('로그인 실패:', err);
        alert('로그인 실패');
      }
    }
  };

  return (
    <Wrapper>
      <Logo src={KAKAO_LOGO} alt="카카고 CI" />
      <FormWrapper>
        <UnderlineTextField placeholder="이메일" value={email} onChange={(e) => setEmail(e.target.value)} />
        <Spacing />
        <UnderlineTextField
          type="password"
          placeholder="비밀번호"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Spacing height={40} />
        <Button onClick={handleLogin}>로그인</Button>
        <Spacing height={20} />
        <Link to="/register">
          <Button>회원가입</Button>
        </Link>
      </FormWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Logo = styled.img`
  width: 88px;
  color: #333;
`;

const FormWrapper = styled.article`
  width: 100%;
  max-width: 580px;
  padding: 16px;

  @media screen and (min-width: ${breakpoints.sm}) {
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 60px 52px;
  }
`;

export default LoginPage;
